.band-profile {
  padding: 0;

  &-hero {
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 500px;

    .title {
      align-items: flex-end;
      display: flex;
      flex-grow: 1;
      font-size: 6rem;
      margin: 0 0 0 4rem;
    }

    &-actions {
      background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);
      display: flex;
      padding: 3rem 4rem;

      .MuiButton-root {
        border-color: white;
        color: white;
        margin-right: 2rem;
      }
    }
  }

  &-bio {
    align-items: center;
    background-color: rgb(15, 41, 60);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4rem 2rem 6rem 2rem;

    .title {
      margin-bottom: 4rem;
    }

    &-content {
      color: white;
    }
  }

  .manage-band-btn {
    bottom: 4rem;
    position: fixed;
    right: 4rem;
  }
}
