@import "../../theme";

.qr-landing-page {
  color: #FFFFFF;
  text-align: center;
  height: 100vh;

  .MuiTypography-h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .MuiTypography-subtitle1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  &-top {
    background: linear-gradient(180deg, rgba(70, 53, 84, 0.9) 0%, rgba(23, 23, 23, 0.36) 100%), url("top-bg.jpg");
    background-position: center;
    background-size: cover;
    min-height: 406px;

    .logo {
      height: 74px;
    }

    .thank-you {
      margin-top: 40px;
    }

    .use-mobile-apps {
      margin-top: 20px;
    }

    .more-about-us {
      margin-top: 10px;
    }

    .MuiTypography-subtitle1 {
      max-width: 182px;
    }

    .custom-btn {
      align-items: center;
      background-color: $rodi-blue;
      border: 1px solid $rodi-blue;
      border-radius: 36px;
      color: #FFFFFF;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      height: 36px;
      justify-content: center;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 0;
      text-decoration: none;
      width: 152px;
    }
  }

  &-bottom {
    background-color: #2A2A2A;
    border-top: 2px solid $rodi-purple;
    padding: 2rem 0;

    .title1 {
      line-height: 28px;
      max-width: 245px;
    }

    .title2 {
      color: #FFFFFF;
      font-weight: 500;
      font-size: 16px;
      text-decoration: none;
    }

    .subtitle1 {
      font-size: 12px;
      font-weight: 400;
      line-height: 26px;
      max-width: 293px;
    }

    .subtitle2 {
      font-size: 12px;
      font-weight: 400;
    }

    .social-media {
      &-button {
        height: 36px;
        width: 36px;
      }
    }
  }

  &-app-stores {
    a {
      display: block;
    }

    .play-store {
      img {
        width: 200px;
      }
    }

    .app-store {
      img {
        padding-right: 10px;
        height: 50px;
      }
    }
  }
}

// Tablet
@media (min-width: 600px) and (max-width: 959px) {
  .qr-landing-page {
    .MuiTypography-h5 {
      font-size: 30px;
      font-weight: 600;
    }

    .MuiTypography-subtitle1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
    }

    &-top {
      min-height: 597px;

      .MuiTypography-subtitle1 {
        max-width: 267px;
      }

      .logo {
        height: 100px;
      }

      .thank-you {
        margin-top: 60px;
      }

      .more-about-us {
        margin-top: 15px;
      }

      .custom-btn {
        font-size: 18px;
        height: 45px;
        margin-top: 20px;
        width: 187px;
      }
    }

    &-bottom {
      border: none;
      min-height: 600px;

      .title1 {
        font-size: 21px;
        line-height: 34px;
        max-width: 326px;
      }

      .title2 {
        font-size: 21px;
        font-weight: 500;
      }

      .subtitle1 {
        font-size: 13px;
        font-weight: 500;
        line-height: 26px;
        max-width: 336px;
      }

      .subtitle2 {
        font-size: 13px;
        font-weight: 600;
        line-height: 26px;
      }

      .mobile-preview-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .mobile-preview {
          background: url("preview@2x.png");
          background-position: center;
          background-size: cover;
          height: 364px;
          width: 178px;
        }
      }
    }

    &-app-stores {
      padding-top: 20px;
    }
  }
}

// Laptop
@media (min-width: 960px) {
  .qr-landing-page {
    .MuiTypography-h5 {
      font-size: 40px;
      font-weight: 600;
    }

    .MuiTypography-subtitle1 {
      font-size: 28px;
      font-weight: 500;
      line-height: 44px;
    }

    &-top {
      min-height: 700px;

      .logo {
        height: 135px;
      }

      .thank-you {
        margin-top: 60px;
      }

      .use-mobile-apps {
        max-width: 100%;
      }

      .more-about-us {
        margin-top: 20px;
        min-width: 363px;
      }

      .custom-btn {
        background-color: #FFFFFF;
        border-color: #FFFFFF;
        color: rgb(0, 0, 8);
        font-size: 24px;
        font-weight: 400;
        height: 61px;
        margin-top: 20px;
        width: 255px;

        &:hover {
          background-color: $rodi-blue;
          border-color: $rodi-blue;
          color: #FFFFFF;
        }
      }
    }

    &-bottom {
      border: none;
      min-height: 700px;
      padding: 0;

      .MuiGrid-container:first-child {
        margin: 0;
      }

      .title1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 52px;
        max-width: 524px;
      }

      .title2 {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
      }

      .subtitle1 {
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
        max-width: 523px;
      }

      .subtitle2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
      }

      .follow-us {
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
      }

      .mobile-preview-container {
        align-items: center;
        display: flex;
        justify-content: center;

        .mobile-preview {
          background: url("preview@3x.png");
          background-position: center;
          background-size: cover;
          height: 548px;
          width: 268px;
          margin: 0 auto;
        }
      }
    }

    &-app-stores {
      padding-top: 20px;

      .play-store {
        img {
          width: 385px;
        }
      }

      .app-store {
        padding: 0 25px 0 25px;
        img {
          height: 100px;
        }
      }
    }
  }
}
