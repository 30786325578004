@import '../../theme';

.dashboard {
  //align-items: center;
  //background: $grey-light;
  //display: flex;
  //height: calc(100vh - 84px);
  height: 100vh;
  //justify-content: center;
}
