@import "../../theme";

.container {
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-style: dashed;
}

.error {
  border-color: #f44336;
}
