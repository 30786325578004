@import "../../theme";

.login {
  &-container {
    height: 100vh;
  }

  &-form-container {
    width: 100%;
  }
}
