@import '../../theme';

.profile {
  align-items: center;
  //background-color: $grey-light;
  display: flex;
  height: calc(100vh - 84px);
  justify-content: center;
  padding: 2rem;

  .card {
    &.loading {
      padding: 2rem;
    }
  }
}
