@import "../../theme";

.alternativeLabel {
  top: 22px !important;
}

.line {
  height: 3px;
  border: 0;
  background-color: #eaeaf0;
  border-radius: 1px;
}

.active {
  .line {
    background-color: $blue-dark;
  }
}

.completed {
  .line {
    background-color: $cyan;
  }
}
