.artist-profile {
	padding: 0;

	&-hero {
		background-position: center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		height: 500px;
		width: 100%;

		.title {
			align-items: flex-end;
			display: flex;
			flex-grow: 1;
			font-size: 6rem;
			margin: 0 0 0 4rem;
		}

		&-actions {
			background: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.7) 0%,
				rgba(0, 0, 0, 0.3) 70%,
				rgba(0, 0, 0, 0) 100%
			);
			display: flex;
			padding: 3rem 4rem;

			.MuiButton-root {
				border-color: white;
				color: white;
				margin-right: 2rem;
			}
		}
	}

	&-section-heading {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		padding-top: 48px;
		width: 100%;
		height: 20px;
		font-size: 24px;
	}

	&-social {
		width: 100%;
		height: 192px;
		background-color: #747474;
		align-items: center;
		justify-content: center;
		padding-top: 24px;
		display: flex;

		&-icon {
			height: 28px;
			width: 28px;
			// did the design just have circles to show an icon should be here or do we want circles behind the icon?
			// background-color: #D9D9D9;
			// border-radius: 50%;
		}

		&-name {
			display: inline-block;
			font-style: normal;
			font-family: 'Roboto';
			color: #ffffff;
			padding-left: 8px;
		}

		&-container {
			margin-right: 24px;
			display: inline-block;
			align-items: center;
			justify-content: center;
			height: 52px;
			width: 180px;
			background-color: #53565a;
			border-radius: 8px;
		}
	}

	&-bio {
		align-items: center;
		background-color: rgb(15, 41, 60);
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 4rem 2rem 6rem 2rem;

		.title {
			margin-bottom: 4rem;
		}

		&-content {
			color: white;
		}
	}

	&-gig {
		display: inline-block;
		padding: 0rem 0rem 0rem 0rem;
		margin-right: 2rem;
		width: 326px;
		height: 282px;

		.image {
			width: 326px;
			height: 198px;
			object-fit: cover;
		}
	}

	&-gig-section-container {
		width: 100%;
		align-items: center;
		justify-content: center;
		display: flex;
		padding-bottom: 40px;
		background: '#1E1E1E';
	}

	&-gig-section-title {
		font-size: 24px;
		width: 73px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 40px;
		padding-top: 48px;
	}

	&-gig-details {
		.name {
			font-family: 'Gotham';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			color: #00263e;
		}
	}

	&-video-section-header {
		text-align: center;
		height: 60px;
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		letter-spacing: 0.01em;
		color: #141414;
		padding-top: 48px;
	}

	&-video-container {
		margin: 1em auto;
		max-width: 40em;
		width: 88%;
		padding-top: 24px;
		padding-bottom: 48px;
	}

	&-video-container iframe {
		aspect-ratio: 16 / 9;
		height: 100%;
		width: 100%;
	}
}

// Moved the About Section styles to ArtistAboutSection.scss
