@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.footerSection {
    margin-top: auto;
    padding-top: 48px;
    width: 100%;
    height: 508px;
        /* Black */

    background: #141414;

    p {
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        width: 70%;
        margin:auto;
    }

    .joinButton {
        /* Button Container */
        display: flex;
        justify-content: center;
        width: 327px;
        height: 52px;
        top: 24px;
        text-align: center;
        margin: auto;

        /* Brand Blue */

        background: linear-gradient(93.87deg, #2EC0FF 0%, #2BB3EE 100%);
        border-radius: 24px;

        /* Button Text */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;

    }

    .followUs {
        margin-top: 56px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.02em;

        color: #FFFFFF;
    }

    .footerSocial {
        display: flex;
        justify-content: center;
        width: 181.92px;
        height: 28px;
        margin: auto;
        margin-top: 16px;

        .facebookLogo {
            width: 28px;
            height: 28px;
            background-image: linear-gradient(#fff,#fff);
            background-repeat: no-repeat;
            background-size: 72% 69.6%;
            background-position: center;
            margin-right: 12px;
        }

        .instagramLogo {
            margin: 0px 12px 0px 12px;
            width: 28px;
            height: 28px;
        }

        .twitterLogo {
            margin: 0px 12px 0px 12px;
            width: 28px;
            height: 28px;
        }

        .youtubeLogo {
            margin: 0px 12px 0px 12px;
            width: 28px;
            height: 28px;
            background-image: linear-gradient(#fff,#fff);
            background-repeat: no-repeat;
            background-size: 35% 35%;
            background-position: center;
            margin-right: 12px;
        }

    }

    .rodiLogo {
        display: flex;
        justify-content: center;
        margin: auto;
        margin-top: 70px;
        width: 147px;
        height: 53px;

    }
}

   