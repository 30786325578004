.band-card {
  &-media {
    height: 250px;
  }

  &-details {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;

    &-followers,
    &-upcoming-gigs {
      align-items: center;
      display: flex;
      line-height: 48px;
    }
  }

  &-social-media {
    display: flex;
    justify-content: space-around;
  }
}
