@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.aboutSection {
    padding: 48px 0px 0px 0px;
    color: #FFFFFF;
    background: #00364D;
    font-family: 'Roboto', sans-serif;
    width: 100%;
       
     &-heading{
        width: 100%;
        height: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 40px;
        letter-spacing: 0.01em;
    }

    img {
        max-width: 443px;
        height: auto;
        display: block;
    }

    .aboutContainer {
        display: flex;
        width: 100%;
        align-items: center;
        padding-bottom: 48px;
      }

      .aboutBox {
        margin: 1%;;
        padding: 1%;
        flex-basis: 46%;

        .aboutBoxText {
            align-content: center;
            width: 559px;
            height: 96px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
          }
      }

    @media screen and (max-width: 769px) {
        .aboutContainer{
          flex-direction: column;
          align-content: center;
        }

        .aboutImg {
          width: 443px;
          max-width: 100vw;
          height: auto;
        }
      }
}


  
  