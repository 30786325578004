@font-face {
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;700&display=swap");
  font-family: 'Montserrat';
  font-weight: auto;
}

$blue: hsl(172, 12.8%, 22.9%);
$turquoise: hsl(166, 94.4%, 72%);
$yellow: hsl(55, 88.2%, 63.5%);
$blue-dark: hsl(213, 33.3%, 18.2%);

$cyan: hsl(206, 92.5%, 63.5%);

$grey-light: hsl(211, 22.8%, 80.2%);

$purple-light: hsl(232, 85.2%, 89.4%);

$primary: $blue;
$info: $turquoise;
$dark: $blue-dark;
$warning: $yellow;

$rodi-blue: hsla(198, 100, 59, 1);
$rodi-purple: hsla(273, 82, 67, 1);

body {
  font-family: 'Montserrat', sans-serif;
}

