@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.home {
	align-content: center;
	display: flex;
	flex-direction: row;
	font-family: Montserrat, Arial, serif;
	justify-content: center;
	width: 100%;

	.column {
		flex-direction: column;
		max-width: 100vw;
		width: 1024px;

		.header {
			display: flex;
			justify-content: space-between;
			padding: 10px 0;

			@media only screen and (max-device-width: 480px) {
				flex-direction: column;
				padding: 10px;
			}

			.logo {
				padding: 20px 60px;

				@media only screen and (max-device-width: 480px) {
					display: flex;
					flex-direction: row;
					justify-content: space-around;
				}
			}

			.topMenu {
				padding: 30px 0;
				text-transform: uppercase;

				@media only screen and (max-device-width: 480px) {
					width: 100%;
					padding: 0;
					text-align: center;
				}

				a {
					color: #1f1f1f;
					font-size: 11px;
					font-weight: 700;
					margin-left: 15px;
				}
			}
		}
		.hero {
			background-image: url('/img/home/hero-bg.png');
			background-size: cover;
			background-position: center;
			min-height: 500px;
			display: flex;
			justify-content: space-between;

			.logoAccentContainer {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-left: 75px;

				@media only screen and (max-device-width: 480px) {
					display: none;
				}

				.logoAccent {
					width: 135px;
					background-repeat: no-repeat;
					background-size: cover;
					height: 167px;
					background-image: url('/img/home/rodi-logo-white.png');

					@media only screen and (max-device-width: 480px) {
						width: 80px;
						height: 90px;
					}
				}
			}

			.callToAction {
				align-items: flex-end;
				background: linear-gradient(to right, transparent, #2a2a2a);
				color: #ffffff;
				display: flex;
				flex-direction: column;
				height: 205px;
				margin-top: 200px;
				padding: 30px 50px;
				text-align: right;
				text-transform: uppercase;
				width: 500px;

				@media only screen and (max-device-width: 480px) {
					margin-top: 150px;
				}

				.catchPhrase {
					font-size: 32px;
					font-weight: 700;
					line-height: 40px;

					@media only screen and (max-device-width: 480px) {
						font-size: 26px;
					}

					.musicHighlighted {
						color: #ccd3ff;
					}
				}

				.downloadContainer {
					align-items: center;
					align-self: flex-end;
					display: flex;
					margin-top: 35px;

					.googlePlay {
						width: 200px;
					}

					.apple {
						display: flex;
						height: 50px;
						margin-bottom: 8px;
						width: auto;
					}
				}
			}
		}

		.description {
			background-image: url('/img/home/description-bg.png');
			background-position: center -5px;
			background-size: cover;
			background-repeat: no-repeat;
			color: #000000;
			display: flex;
			font-family: Montserrat, serif;
			justify-content: space-between;
			min-height: 500px;

			.descriptionText {
				color: #01132a;
				padding: 50px 0 0 90px;
				width: 625px;

				@media only screen and (max-device-width: 480px) {
					padding: 35px;
				}

				h2 {
					font-size: 30px;
					font-weight: 800;
					letter-spacing: -1px;
					line-height: 35px;
					text-transform: uppercase;
				}

				p {
					font-size: 14px;
					font-weight: 700;
					margin: 15px 0;
					line-height: 24px;
				}
			}

			.descriptionLogo {
				margin-top: 30px;
				width: 375px;

				@media only screen and (max-device-width: 480px) {
					display: none;
				}

				img {
					margin-left: 35px;
				}
			}
		}

		.ecosystem {
			background-image: url('/img/home/ecosystem-bg.png');
			background-position: center;
			background-size: cover;
			display: flex;
			justify-content: space-between;
			min-height: 500px;

			.sideTextContainer {
				display: flex;
				flex-direction: column;
				font-family: Montserrat, serif;
				width: 75px;

				@media only screen and (max-device-width: 480px) {
					position: absolute;
					padding: 20px;
				}

				.sideText {
					color: #ffffff;
					justify-content: space-around;
					transform-origin: 38% 347%;
					transform: rotate(270deg);
					width: 800px;

					@media only screen and (max-device-width: 480px) {
						transform: none;
					}

					h2 {
						font-size: 28px;
						font-weight: 900;
						text-transform: uppercase;
					}

					h3 {
						font-size: 11px;
						font-weight: 500;
					}
				}
			}

			.ecosystemParts {
				display: flex;
				padding: 80px 30px;

				@media only screen and (max-device-width: 480px) {
					flex-direction: column;
					padding: 100px 3px;
				}

				.part {
					.descriptionBox {
						color: #000000;
						font-size: 10px;
						font-weight: 600;
						line-height: 18px;
						height: 155px;
						padding: 15px;
						width: 250px;
						margin: 5px;

						@media only screen and (max-device-width: 480px) {
							height: 175px;
							width: 175px;
							margin-top: 0;
							font-size: 10px;
							line-height: 15px;
						}
					}

					.title {
						background-color: rgba(15, 30, 47, 0.5);
						font-size: 49px;
						font-weight: 700;
						line-height: 36px;
						margin: 84px 5px;
						padding: 5px 40px;
						position: absolute;
						text-transform: uppercase;
						width: 250px;
						z-index: 1;

						@media only screen and (max-device-width: 480px) {
							font-size: 39px;
							margin: 50px 5px;
							width: 175px;
						}

						.smaller {
							font-size: 35px;
							font-weight: 600;
						}
					}

					.titleBox {
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						filter: grayscale(100%) sepia(100%) hue-rotate(180deg);
						height: 250px;
						width: 250px;
						margin: 5px;

						@media only screen and (max-device-width: 480px) {
							height: 175px;
							width: 175px;
						}
					}
				}

				.theBand {
					@media only screen and (max-device-width: 480px) {
						display: flex;
						flex-direction: row;
					}

					.titleBox {
						background-image: url('/img/home/ecosystem-the-band-bg.png');
					}

					.title {
						color: #ccd3ff;
					}

					.descriptionBox {
						background-color: #ccd3ff;
						margin-top: 15px;

						@media only screen and (max-device-width: 480px) {
							margin-top: 5px;
						}
					}
				}

				.theVenue {
					@media only screen and (max-device-width: 480px) {
						display: flex;
						flex-direction: row;
					}

					.titleBox {
						background-image: url('/img/home/ecosystem-the-venue-bg.png');
					}

					.title {
						color: #dad3c0;
						@media only screen and (max-device-width: 480px) {
							margin-left: 0;
							padding: 5px 15px;
						}
					}

					.descriptionBox {
						background-color: #dad3c0;
						margin-bottom: 15px;

						@media only screen and (max-device-width: 480px) {
							margin-top: 5px;
							margin-bottom: 0;
						}
					}
				}

				.theUser {
					@media only screen and (max-device-width: 480px) {
						display: flex;
						flex-direction: row;
					}

					.titleBox {
						background-image: url('/img/home/ecosystem-the-user-bg.png');
					}

					.title {
						color: #c0ccda;
					}

					.descriptionBox {
						background-color: #c0ccda;
						margin-top: 15px;

						@media only screen and (max-device-width: 480px) {
							margin-top: 5px;
							font-size: 9px;
						}
					}
				}
			}
		}

		.learn {
			color: #000000;
			min-height: 500px;
			padding: 60px;

			@media only screen and (max-device-width: 480px) {
				padding: 25px;
			}

			h2 {
				font-size: 34px;
				font-weight: 800;
				letter-spacing: -1px;
				line-height: 38px;
				margin-bottom: 40px;
				text-transform: uppercase;

				@media only screen and (max-device-width: 480px) {
					font-size: 27px;
				}
			}

			.advantages {
				display: flex;
				justify-content: space-between;

				@media only screen and (max-device-width: 480px) {
					flex-direction: column;
				}

				.advantage {
					width: 285px;

					@media only screen and (max-device-width: 480px) {
						margin-bottom: 20px;
					}

					img {
						width: 300px;
						height: 150px;
					}

					h3 {
						font-weight: 700;
						margin: 15px 0;
						font-size: 14px;
					}

					p {
						color: #6f6f6f;
						font-size: 13px;
						font-weight: 500;
						line-height: 22px;
					}
				}
			}
		}

		.aboutUs {
			background-color: #3240a0;
			color: #ffffff;
			display: flex;
			min-height: 500px;

			@media only screen and (max-device-width: 480px) {
				flex-direction: column;
			}

			.sideTitle {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				width: 100px;

				@media only screen and (max-device-width: 480px) {
					font-size: 32px;
					margin: 20px 25px 0;
				}

				h1 {
					color: #ffffff;
					font-size: 60px;
					font-weight: 900;
					text-transform: uppercase;
					transform-origin: 25% 162%;
					transform: rotate(270deg);
					width: 500px;
					z-index: 1;

					@media only screen and (max-device-width: 480px) {
						transform: none;
						font-size: 32px;
					}
				}

				.shadowHeader {
					color: #4653a9;
					font-size: 52px;
					font-weight: 600;
					position: absolute;
					transform-origin: 31% 165%;
					transform: rotate(270deg);
					z-index: 0;

					@media only screen and (max-device-width: 480px) {
						transform: none;
						font-size: 22px;
						margin-top: 35px;
						margin-left: 20px;
					}
				}
			}

			.details {
				margin: 30px;

				@media only screen and (max-device-width: 480px) {
					margin: 0 30px;
				}

				p {
					padding: 10px 0;
					font-size: 13px;
					line-height: 22px;
				}

				.bios {
					display: flex;
					margin-top: 10px;

					@media only screen and (max-device-width: 480px) {
						flex-direction: column;
						margin-bottom: 20px;
					}

					.bio {
						display: flex;

						.bioCard {
							display: flex;
							flex-direction: column;
							width: 180px;

							@media only screen and (max-device-width: 480px) {
								width: 190px;
							}

							img {
								height: 180px;
								object-fit: cover;
							}

							h3 {
								font-size: 17px;
								font-weight: 600;
								margin-top: 15px;
							}

							.jobTitle {
								font-size: 13px;
							}

							.socials {
								margin-top: 8px;

								img {
									height: 27px;
									width: 27px;
									margin-right: 4px;
								}
							}
						}

						p {
							color: #f0f0f0;
							font-size: 9px;
							line-height: 16px;
							margin: 0 15px;
							padding: 0;
							width: 200px;

							@media only screen and (max-device-width: 480px) {
								width: 175px;
							}
						}

						.right {
							text-align: right;

							.socials img {
								margin-left: 4px;
								margin-right: 0;
							}
						}
					}

					.divider {
						height: 180px;
						width: 1px;
						background-color: #9f9f9f;

						@media only screen and (max-device-width: 480px) {
							height: 1px;
							width: 100%;
							margin: 20px 0;
						}
					}
				}
			}
		}

		.testimonials {
			display: flex;
			padding: 55px 40px;

			@media only screen and (max-device-width: 480px) {
				flex-direction: column;
			}

			.sideHeader {
				display: flex;
				flex-direction: column;
				width: 400px;

				hr {
					border-bottom: 3px;
					border-color: #3240a0;
					border-style: solid;
					border-top: 2px;
					height: 3px;
					margin: -12px 0 14px 0;
					width: 30px;
				}

				h2 {
					color: #000000;
					font-size: 28px;
					font-weight: 500;
					line-height: 32px;
					margin-bottom: 12px;
					text-transform: uppercase;

					@media only screen and (max-device-width: 480px) {
						font-size: 22px;
					}

					strong {
						color: #000000;
						font-weight: 900;
					}
				}

				.subheader {
					font-size: 14px;
					font-weight: 500;
				}
			}

			.reviews {
				display: flex;

				.review {
					box-shadow: 0 2px 5px #cccccc;
					color: #000000;
					display: flex;
					flex-direction: column;
					height: 140px;
					margin: 0 15px;
					padding: 20px;
					width: 255px;

					@media only screen and (max-device-width: 480px) {
						margin-left: 0;
						margin-top: 15px;
						padding: 10px;
						height: 175px;
					}

					p {
						font-size: 10px;
						font-weight: 500;
						line-height: 18px;
						margin-bottom: 10px;
					}

					.reviewer {
						display: flex;

						.name {
							font-weight: 700;
							font-size: 13px;
						}

						img {
							height: 40px;
							margin-right: 10px;
							width: 40px;
						}

						.rating {
							color: #3240a0;
							letter-spacing: 10px;

							@media only screen and (max-device-width: 480px) {
								letter-spacing: 0;
							}
						}
					}
				}
			}
		}

		.contact {
			background-image: url('/img/home/contact-bg.png');
			background-position: top;
			background-size: contain;
			color: #ffffff;
			min-height: 500px;
			text-align: center;
			padding: 30px;
			background-repeat: no-repeat;
			background-color: #1b1b22;

			h2 {
				font-size: 34px;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: -1px;

				strong {
					color: #ffffff;
				}
			}

			.subheader {
				text-align: center;
				font-weight: 500;
				font-size: 19px;
			}

			.dividerContainer {
				display: flex;
				justify-content: space-around;

				hr {
					border: 2px solid #f7e715;
					width: 80px;
					margin-top: 5px;
				}
			}

			.formContainer {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				margin-bottom: 30px;

				a {
					color: #f7e715;
				}

				form {
					width: 500px;

					.row {
						display: flex;
						justify-content: space-between;
						margin: 30px 0;
					}

					.buttonContainer {
						display: flex;
						justify-content: space-around;
					}

					input {
						background: transparent;
						border-top: none;
						border-right: none;
						border-left: none;
						border-bottom: 1px solid #cfcfcf;
						padding: 2px 5px;
						width: 100%;

						&:focus {
							outline: none;
						}

						&.message {
							margin-top: 50px;
						}

						&.halfWidth {
							width: 47%;
						}
					}

					input:focus {
						border: none;
					}

					button {
						background-color: #f7e715;
						border: 0;
						color: #1b1b22;
						cursor: pointer;
						font-size: 11px;
						font-weight: 700;
						padding: 10px 30px;
					}
				}
			}

			.logo {
				img {
					filter: brightness(0) invert(1);
				}
			}

			.disclaimerContainer {
				display: flex;
				justify-content: space-around;
				margin-top: 15px;

				.disclaimer {
					text-align: center;
					font-size: 12px;
					max-width: 475px;
				}
			}

			.phoneAddress {
				color: #45454e;
				display: flex;
				justify-content: space-around;
				margin: 50px 0;

				@media only screen and (max-device-width: 480px) {
					display: flex;
					flex-direction: column;
				}

				.contactData {
					line-height: 30px;
					margin-left: 20px;
					font-size: 11px;
					font-weight: 600;
				}

				.phone {
					display: flex;
					justify-content: space-around;
					width: 120px;

					img {
						width: 20px;
						height: 20px;
						margin-top: 5px;
					}
				}

				.address {
					display: flex;
					justify-content: space-around;
					width: 240px;

					@media only screen and (max-device-width: 480px) {
						margin-left: 3px;
						margin-top: 10px;
					}

					img {
						width: 15px;
						height: 25px;
					}
				}
			}

			.socialLinks {
				.socialLink {
					background-color: #ffffff;
					border-radius: 50%;
					display: inline-block;
					height: 35px;
					margin: 10px;
					width: 35px;
				}
			}

			.footer {
				margin-top: 20px;
				font-size: 12px;
				font-weight: 500;
			}
		}
	}

	.scrollDown {
		color: #2a2a2a;
		display: flex;
		flex-direction: row;
		font-family: Montserrat, Arial, serif;
		margin-left: 1125px;
		max-width: 100vw;
		position: absolute;
		transform: rotate(270deg);
		width: 1179px;

		@media only screen and (max-device-width: 480px) {
			display: none;
		}

		.text {
			font-size: 12px;
			font-weight: 700;
			letter-spacing: 10px;
			text-transform: uppercase;
		}

		.socialLinks {
			margin-left: 10px;

			img {
				height: 24px;
				margin: -3px 5px;
				transform: rotate(90deg);
				width: 24px;
			}
		}

		hr {
			border-bottom: 1px solid #2a2a2a;
			height: 1px;
			margin: 9px 20px;
			width: 50px;
		}
	}
}
