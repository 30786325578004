@import "../../theme";

.custom-navbar {
  background-color: $blue-dark !important;

  &-container {
    display: flex;
    justify-content: space-between;

    .logo {
      height: 30px;
    }
  }
}
