@import "../../theme";

.root {
  background-color: #ccc;
  z-index: 1;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.active {
  background-image: linear-gradient( 136deg, $blue 0%, $blue-dark 100%);
  box-shadow: 0 4px 10px 0 rgba(0,0,0,.25);
}

.completed {
  background-color: $cyan;
}
