.pageNotFound {
	align-content: center;
	text-align: center;
	padding-top: 46px;
	padding-bottom: 32px;

	&-navHome {
		display: inline-block;
		margin-top: 32px;
	}

	&-background {
		background-image: url('/img/home/hero-bg.png');
		background-size: cover;
		min-height: 800px;
		display: flex;

		margin-top: 46px;
	}
}
