.band-members {
  align-items: center;
  background-color: lighten(rgb(15, 41, 60), 5%);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 2rem 6rem 2rem;

  .title {
    margin-bottom: 4rem;
  }
}
