.custom-drawer-menu {
  .current-user {
    align-items: center;
    display: flex;
    padding: 8px 16px;

    .MuiAvatar-root {
      margin-right: 16px;
    }
  }
}
