.custom-snackbar {
  &.success {
    .MuiSnackbarContent-root {
      color: white;
      background-color: #4caf50;
    }
  }

  &.error {
    .MuiSnackbarContent-root {
      color: white;
      background-color: #f44336;
    }
  }
}
